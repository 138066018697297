import { Grid } from '@material-ui/core';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import React, { useState } from 'react';
import styled from 'styled-components';

import Input from '../Input';
import InternalLink from '../InternalLink';
import Layout from '../Layout';
import PageTitle from '../PageTitle';
import SmallText from '../SmallText';
import Spacer from '../Spacer';
import SubmitButton from '../SubmitButton';
import Textblob from '../Textblob';

const Wrapper = styled.div`
  text-align: center;
  a {
    text-decoration: underline;
  }
`;

const Section = styled.section`
  margin-top: 3rem;
`;

export default ({ pageContext: { searchIndex } }) => {
  const [formValues, setFormValues] = useState({
    MERGE0: '',
    MERGE1: '',
    MERGE2: '',
  });

  const handleFormInput = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Layout background={true} searchIndex={searchIndex}>
        <PageTitle>Newsletter</PageTitle>
        <Grid container spacing={24} justify="center">
          <Grid item xs={12} sm={6} md={4}>
            <Section>
              <Textblob>
                For quarterly updates on our creative endeavours, subscribe to
                our newsletter and look forward to receiving insider info and
                selected imagery of our favourite projects. We’d love to take
                you on our journey with us!
              </Textblob>
            </Section>
            <Section>
              <MailchimpSubscribe
                url={
                  'https://florafaunavisions.us2.list-manage.com/subscribe/post?u=72b28c6cc0b783968ae35fc23&amp;id=17599cb36a'
                }
                render={({ subscribe, status, message }) => {
                  return (
                    <Wrapper>
                      <Input
                        type="email"
                        name="MERGE0"
                        id="MERGE0"
                        placeholder="Your e-mail address"
                        value={formValues.MERGE0}
                        onChange={handleFormInput}
                      />
                      <Input
                        type="text"
                        name="MERGE1"
                        id="MERGE1"
                        placeholder="Your last name"
                        value={formValues.MERGE1}
                        onChange={handleFormInput}
                      />
                      <Input
                        type="text"
                        name="MERGE2"
                        id="MERGE2"
                        placeholder="Your first name"
                        value={formValues.MERGE2}
                        onChange={handleFormInput}
                      />
                      <Spacer space="2rem" />
                      <SmallText style={{ textAlign: 'left' }}>
                        Please note that we rely on Mailchimp to distribute our
                        newsletter. By clicking below to subscribe, you
                        acknowledge that your information will be transferred to
                        Mailchimp for processing. Learn more about Mailchimp's
                        privacy practices{' '}
                        <InternalLink
                          small
                          href="https://mailchimp.com/legal/"
                          target="_blank"
                          inline
                        >
                          here.
                        </InternalLink>
                      </SmallText>
                      {status === 'sending' && (
                        <Textblob style={{ marginTop: '1.5rem' }}>
                          Sending…
                        </Textblob>
                      )}
                      {status === 'success' && (
                        <Textblob style={{ marginTop: '1.5rem' }}>
                          Thanks for your subscription!
                        </Textblob>
                      )}
                      {status === 'error' && (
                        <Textblob style={{ marginTop: '1.5rem' }}>
                          {message}
                        </Textblob>
                      )}
                      <Spacer space="2rem" />
                      <SubmitButton
                        onClick={() => {
                          subscribe(formValues);
                          setFormValues({
                            MERGE0: '',
                            MERGE1: '',
                            MERGE2: '',
                          });
                        }}
                      >
                        Subscribe
                      </SubmitButton>
                    </Wrapper>
                  );
                }}
              />
            </Section>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};
